.wrapper{
    background-image: url("../images/img2.PNG");
}
.img3{
    width: 1000px;
    overflow: hidden;
    position: relative;
    top:250px;
    right: 300px;
    transform: rotate(-90deg) 
}
.h1{
    font-weight: 500;
    font-size: 40px;
    position: relative;
    top: 150px;
    left: 70px;
}


.h2{
    background-color: red;
    color: white;
}
.p1{
    font-weight: 1500;
}
.img1{
    width: 900px;
    position: relative;
    right: 200px;
    top:100px
}
.me-2{
    width: 200px;
    font-size: 20px;
}
@media only screen and (max-width: 600px) {
     .img1{
        width: 500px;
        position: relative;
        right: 90px;
        top:20px
    }
    .me-2{
        width: 100px;
    }
    .img3{
        width: 500px;
        overflow: hidden;
        position: relative;
        top:220px;
        right: 200px;
        transform: rotate(-90deg) 
    }
    .h1{
        font-weight: 500;
        font-size: 40px;
        position: relative;
        top: 100px;
        left: 90px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .img1{
       width: 600px;
       position: relative;
       right: 90px;
       top:50px
   }
   .me-2{
       width: 100px;
   }
   .img3{
       width: 500px;
       overflow: hidden;
       position: relative;
       top:120px;
       right: 130px;
       transform: rotate(-90deg) 
   }
   .h1{
       font-weight: 500;
       font-size: 40px;
       position: relative;
       top: 100px;
       left: 50px;
   }
}
/* @media only screen and (max-width: 738px) {
    
   .img3{
    width: 1000px;
    overflow: hidden;
    position: relative;
    top:240px;
    right: 300px;
       transform: rotate(-90deg) 
   }
   .img1{
    width: 900px;
    position: relative;
    right: 200px;
    top:100px
}
.h1{
    font-weight: 500;
    font-size: 40px;
    position: relative;
    top: 100px;
    left: 70px;
}
.me-2{
    width: 200px;
    font-size: 20px;
}
} */